<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :search="search"
    hide-default-footer
    :items-per-page="-1"
  >
    <template v-slot:item.optionalSlot.code="{ item }">
      <LookupValue short :value="item.optionalSlot" />
    </template>
    <template v-slot:item.entryAllowed="{ item }">
      <v-simple-checkbox
        v-model="item.entryAllowed"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.exitAllowed="{ item }">
      <v-simple-checkbox
        v-model="item.exitAllowed"
        disabled
      ></v-simple-checkbox>
    </template>
  </v-data-table>
</template>

<script>
import LookupValue from 'common/components/LookupValue.vue';
export default {
  name: 'OptionalAvailability',
  components: { LookupValue },
  props: ['search'],
  data() {
    return {
      items: [],
      headers: [
        {
          text: 'Fach',
          value: 'subject.description',
        },
        { text: 'Schiene', value: 'optionalSlot.code' },
        { text: 'Semester', value: 'semester.description' },
        { text: 'Stufe', value: 'grade.description' },
        { text: 'Eintritt', value: 'entryAllowed' },
        { text: 'Austritt', value: 'exitAllowed' },
      ],
    };
  },
  async created() {
    const unsorted = await this.apiList({ resource: 'optional/availability' });
    this.items = unsorted.sort((a, b) => {
      if (a.subject.description > b.subject.description) {
        return 1;
      }
      if (a.subject.description < b.subject.description) {
        return -1;
      }
      if (a.grade.id > b.grade.id) {
        return -1;
      }
      if (a.grade.id < b.grade.id) {
        return 1;
      }
      if (a.semester.id > b.semester.id) {
        return 1;
      }
      if (a.semester.id < b.semester.id) {
        return -1;
      }
    });
  },
};
</script>
